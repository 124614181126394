/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

@import '~quill/dist/quill.snow.css';


@media(pointer: fine) {
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #888
  }
  .inner-scroll {
    scrollbar-width: thin
  }
}



$number: 0, 2, 4, 5, 6, 8, 10, 12, 14, 15, 16, 18, 20, 22, 24, 25, 26, 28, 30, 32, 36, 38, 40, 50, 60, 65, 100, 200, 300,
  400, 500, 600;

@each $set in $number {
  [size-#{$set}] {
    font-size: $set + px;
  }
  [lh-#{$set}] {
    line-height: $set + px !important;
  }
  [margin-#{$set}] {
    margin: $set + px !important;
  }
  [mv-#{$set}] {
    margin: $set + px 0 !important;
  }
  [mt-#{$set}] {
    margin-top: $set + px !important;
  }
  [mb-#{$set}] {
    margin-bottom: $set + px !important;
  }
  [ml-#{$set}] {
    margin-left: $set + px !important;
  }
  [mr-#{$set}] {
    margin-right: $set + px !important;
  }
  [ma-#{$set}] {
    margin: $set + px !important;
  }
  [pt-#{$set}] {
    padding-top: $set + px !important;
  }
  [pb-#{$set}] {
    padding-bottom: $set + px !important;
  }
  [pl-#{$set}] {
    padding-left: $set + px !important;
  }
  [pr-#{$set}] {
    padding-right: $set + px !important;
  }
  [pa-#{$set}] {
    padding: $set + px !important;
  }
  [m-auto] {
    margin:auto !important;
  }
  [ta-center] {
    text-align:center !important;
  }
  [ta-left] {
    text-align:left !important;
  }
  [ta-right] {
    text-align:right !important;
  }
  [margintop#{$set}] {
    margin-top: $set + px !important;
  }
  [fw-#{$set}] {
    font-weight: $set !important;
  }
  [pd-#{$set}] {
    padding: $set + px !important;
  }
  [lh-#{$set}] {
    line-height: $set + px !important;
  }
}


.swiper-pagination {
  bottom: 20px !important;
  bottom: calc(env(safe-area-inset-bottom) + 20px) !important;
  }
p {
    font-size: 14px;
    margin: 0px;
    color: #393939;
  }
  h1 {
    font-size: 50px;
    color: #393939;
    margin: 0px;
  }
  h2 {
    font-size: 34px;
    color: #393939;
    margin: 0px;
  }
  
  h3 {
    // font-size: 34px;
    // color: #393939;
    margin: 0px;
  }
  
  h4 {
    color: #393939;
    // color: #263238;
    margin: 0px;
  }
  
  h5 {
    // font-size: 34px;
    color: #0b222e;
    margin: 0px;
  }
  
  h6 {
    color: #393939;
    margin: 0px;
    font-size: 14px;
  }
  
  .toast-header {
    color: #222;
  }
  
  .toast-message {
    color: #222;
  }

  .padding-page{
    padding: 16px;
  }

  // .tambah-tambah{
  //   padding: 14px;
  // }
  .separator {
    height: 10px;
    width: 100%;
    background: var(--ion-color-tertiary);
  }
  .block-table{
    border-radius: 6px;
    background:  #EDF1F5;
    width: fit-content;
    padding: 4px 8px;
  }

  .block-aktif{
    border-radius: 6px;
    background:  #F0EDFF;
    width: fit-content;
    padding: 4px 8px;
    p{
      color: #7B61FF;
    }
    h6{
      color: #7B61FF;
    }
  }

  .block-surveyor{
    border-radius: 6px;
    background:  #D1FEE5;
    width: fit-content;
    padding: 4px 8px;
    p{
      color: #0DA650;
    }
    h6{
      color: #0DA650;
    }
  }

  .block-sales{
    border-radius: 6px;
    background:  #FFDDD9;
    width: fit-content;
    padding: 4px 8px;
    p{
      color: #DD4B39;
    }
    h6{
      color: #DD4B39;
    }
  }

  .block-kolektor{
    border-radius: 6px;
    background:  #DFF6FB;
    width: fit-content;
    padding: 4px 8px;
    p{
      color: #65B8DB;
    }
    h6{
      color: #65B8DB;
    }
  }

  .block-kuning{
    border-radius: 6px;
    background:  #FFEFD8;
    width: fit-content;
    padding: 4px 8px;
    p{
      color: #E48900;
    }
    h6{
      color: #E48900;
    }
  }

  .text-bottom-input{
    font-size: 11px;margin-top: 5px;margin-left: 5px;
  }
  
  .custom-toast {
    // --left: unset !important;
    // --right: 36px !important;
    --min-width: 252px !important;
    --max-width: 288px !important;
    color: #222;
    --start: unset;
    --end: 25px;
    --border-radius: 8px;
    top: 20px;
  }
  
  .custom-toast-btn {
    color: #42ab00;
  }
  
  .toast-wrapper {
    min-width: 252px !important;
    left: unset !important;
    right: 36px !important;
  }
  
  ion-card {
    border-radius: 20px;
    box-shadow: none;
    padding: 12px;
  }
  
  ion-content {
    --background: #f5f6f9;
    ::-webkit-scrollbar,
    *::-webkit-scrollbar {
      display: none;
    }
  }
  
  .scroll_container {
    white-space: nowrap;
    overflow: hidden;
    overflow-y: auto;
    height: 500px;
  
    .item_scroll {
      display: contents;
      width: auto;
      height: auto;
      border-radius: 10px 0px 10px 10px;
      margin: 0 5px;
      // padding: 0px 5px;
      border: solid 1px #c0c0c0;
  
      &:first-child {
        margin-left: 13px;
      }
  
      &:last-child {
        margin-right: 13px;
      }
  
      .img_box {
        width: 100%;
        height: 100%;
      }
    }
  }
  
  ::-webkit-scrollbar,
  *::-webkit-scrollbar {
    display: none;
  }

  .scroll-content {
    overflow-y: hidden !important;
}
  
  .export-css{
    --width: 135px;
    --border-radius: 10px;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .notif-css{
    --width: 400px;
    --max-height: 55%;
    --border-radius: 30px;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .wd-req{
    --width: 400px;
    --border-radius: 15px;
    --max-height: 62%;
  }
  
  .dataa{
    border-bottom: 1px solid #ebebebeb;
    padding: 10px 0px;
  
    ion-col{
      h6{
        color: #6F6F6F;
        font-size: 16px;
      }
    }
  }
  
  .primary{
    color: var(--ion-color-primary);
  }
  
  
  .mobilev {
    display: none;
  }
  .mobileflex {
    display: none;
  }
  .desktopv {
    display: block;
  }
  .desktopflex {
    display: flex;
  }
  .wd-req{
    --width: 520px;
    --border-radius: 15px;
    --max-height: 62%;
  }

  .wd-req2{
    --width: 357px;
    --border-radius: 15px;
    --max-height: 50%;
  }

  .wd-konfirmasi{
    --width: 357px;
    --border-radius: 15px;
    --max-height: 50%;
  }

  .wd-reqFull{
    --width: 520px;
    --border-radius: 15px;
    --max-height: 100%;
  }

  .add-assPartner{
    --width: 80%;
    --border-radius: 15px;
    --max-height: 90%;
    --min-height: 90%;
  }

  .det-assPartner{
    --width: 30%;
    --border-radius: 15px;
    --max-height: 90%;
    --min-height: 90%;
  }

  .notif-css{
    --width: 400px;
    --max-height: 79%;
    --border-radius: 30px;
    --offset-x: -19px;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .item-input {
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    --background: transparent !important;
    --inner-padding-end: 4px !important;
  }
  
  .label-input {
    display: flex !important;
    background: transparent !important;
    z-index: 99 !important;
    width: fit-content !important;
    margin-left: 10px !important;
    font-size: 18px !important;
    margin-bottom: 10px !important;
  }
  
  .input-input {
    border: 1px solid #bcc2cc !important;
    border-radius: 6px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    --padding-top: 15px !important;
    --padding-bottom: 15px !important;
  }
  
  .has-focus.sc-ion-input-md-h {
    // border: 2px solid var(--ion-color-primary) !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    border: 2px solid #222 !important;
    background: #ffffff21;
  }
  
  .login-focus {
    .has-focus.sc-ion-input-md-h {
      border: none !important;
    }
  }
  
  .has-focus.sc-ion-input-md-h {
    border: 2px solid var(--ion-color-primary) !important;
    background: transparent;
  }

  .item-label-stacked .select-icon{
    transform: translate3d(0,  0px,  0);
  }

  ion-select .select-icon {
    transform: translate3d(0,  0px,  0) !important;
  }

  .hr{
    border-bottom: 1px solid #DCE0E5;
    margin: 12px 12px;
    width: 100%;
  }

  .modalConfirmation{
    --width: 353px;
    --height: 447px;
    --border-radius: 20px;
  }

  .modalTransfer{
    --width: 500px;
    --height: 352px;
    --border-radius: 20px;
  }

  .modalForm{
    --width: 488px;
    --height: 90%;
    --border-radius: 12px;
  }

  .modalQuotaForm{
    --width: 488px;
    --height: 40%;
    --border-radius: 12px;
  }

  .modalDataImport{
    --width: 1053px;
    --height: 67%;
    --border-radius: 12px;
  }

  .modalPreviewImage{
    --width: 760px;
    --height: 90%;
    --border-radius: 12px;
  }

  .modalBankForm{
    --width: 488px;
    --height: 57%;
    --border-radius: 12px;
  }

  .modalMaps{
    --width: 488px;
    --height: 28%;
    --border-radius: 12px;
  }

  .popOv-daterange{
    --width: 606px;
    --height: 58%%;
    --border-radius: 12px;
  }

  .btn-merah{
    --background: #BF2841;
  }

  .inner-scroll {
    overflow: hidden !important;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 5px; /* Lebar scroll bar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Warna latar belakang track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Warna thumb scroll bar */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Warna thumb scroll bar saat hover */
}


  
  // .native-input.sc-ion-input-md {
  //   padding-left: 10px !important;
  //   padding-right: 10px !important;
  // }


  @media only screen and (max-width: 768px) {
    .mobilev {
      display: block;
      // padding: 15px;
    }
    .mobileflex {
      display: flex;
      // padding: 15px;
    }
    .desktopflex {
      display: none !important;
    }
    .desktopv {
      display: none !important;
    }
  
    .notif-css{
      --width: 307px;
      --max-height: 80%;
      --border-radius: 30px;
      --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    ion-card {
      margin: 3px;
    }
    .modal-wrapper.sc-ion-modal-md, .modal-shadow.sc-ion-modal-md{
      width:90%;
      --width:90%;
    }
  
  
  }

  // .has-focus.sc-ion-input-md-h{
  //   border: none !important;
  // }

  .link{
    cursor: pointer;
  }
  
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.alert-wrapper.sc-ion-alert-md {
  border-radius: 10px;
}

.required{
    color: #ff3939;
}

.warnText{
  color: var(--ion-color-danger);
  font-size: 11px
}

ion-select {
  .select-icon {
    .select-icon-inner {
      top: 85% !important;
    }
  }
}


@import '~@angular/material/theming';
@include mat-core();

$primary: mat-palette($mat-blue);
$accent: mat-palette($mat-pink, A200, A100, A400);

$theme: mat-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
    ),
  )
);

@include angular-material-theme($theme);

// Customizing the checkbox colors
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #007bff !important; /* Custom background color */
  border-color: #007bff !important; /* Custom border color */
}

.mat-checkbox .mat-checkbox-checkmark-path {
  stroke: #fff !important; /* Custom checkmark color */
}
